import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { appFetch } from "../utils/fetch"; // בדוק שהנתיב נכון

const HokBankPage = () => {
  const [payments, setPayments] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // שורות מסומנות
  const [openDialog, setOpenDialog] = useState(false); // מצב לפתיחת הדיאלוג
  const [serialNumber, setSerialNumber] = useState(''); // שדה קלט למספר סיריאלי
  const [billingDate, setBillingDate] = useState(''); // שדה קלט לתאריך חיוב לקוח
  const { enqueueSnackbar } = useSnackbar();

  // פונקציה להבאת נתונים מהשרת
  const fetchPayments = async () => {
    try {
      const paymentType = encodeURIComponent("הוק בנקאית");
      const res = await appFetch(
        `api/zohoPayments/paymentsByPayTypeForAdmin/${paymentType}`,
        {
          method: "GET",
        }
      );
      if (!res.ok) throw new Error("Failed to fetch payments");
  
      const data = await res.json();
      if (data && data.length > 0) {
        // מעבר על כל אובייקט במערך והמרת bankId לסטרינג
        const updatedData = data.map(payment => ({
          ...payment,
          bankId: String(payment.bankId), // המרת bankId לסטרינג
        }));
  
        setPayments(updatedData); // שמירת הנתונים במצב לאחר השינוי
      } else {
        setPayments([]); // במידה ואין תשלומים
      }
    } catch (error) {
      console.error("Error fetching payments:", error);
      enqueueSnackbar("שגיאה בטעינת התשלומים", { variant: "error" });
    }
  };
  

  // קריאה ל-API בעת טעינת הקומפוננטה
  useEffect(() => {
    fetchPayments();
  }, []);
  
  // פונקציה להראות את התשלומים שנבחרו
  const handleShowPayments = async () => {
    if (selectedRows.length === 0) {
      enqueueSnackbar("לא נבחרו תשלומים", { variant: "error" });
      return;
    }
  
    const selectedPayments = selectedRows.map(rowId => payments.find(p => p.payeeNumber === rowId)).filter(p => p);
  
    try {
      console.log(selectedPayments)
      const largePayments = [];
const smallPayments = [];

selectedPayments.forEach(payment => {
  // המרת accountId ו-branchId למחרוזת, אם הם קיימים
  const accountIdStr = payment.accountId ? payment.accountId.toString() : '';
  const branchIdStr = payment.branchId ? payment.branchId.toString() : '';

  // הסרת כל האפסים המובילים מ-accountId ו-branchId
  const sanitizedAccountId = accountIdStr.replace(/^0+/, '');
  const sanitizedBranchId = branchIdStr.replace(/^0+/, '');

  // עדכון התשלום לאחר הסרת האפסים המובילים
  payment.accountId = sanitizedAccountId;
  payment.branchId = sanitizedBranchId;

  // בדיקה אם לאחר הסרת האפסים יש יותר מ-9 ספרות ב-accountId או יותר מ-3 ספרות ב-branchId
  if (sanitizedAccountId.length > 9 || sanitizedBranchId.length > 3) {
    largePayments.push(payment);
  } else {
    smallPayments.push(payment);
  }
});

console.log('Large payments:', largePayments);
console.log('Small payments:', smallPayments);


      
      
      const response =await appFetch('/api/zohoPayments/generateMasavFile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          payments: smallPayments, 
          serialNumber,  // הוספת המספר הסיריאלי
          billingDate    // הוספת תאריך החיוב
        }),
      });

      if (!response.ok) throw new Error('Failed to generate masav file');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'masav_file.bin');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error generating masav file:", error);
      enqueueSnackbar("שגיאה ביצירת קובץ המסב", { variant: "error" });
    }
  };

  // פתיחת הדיאלוג
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  // סגירת הדיאלוג
  const handleCloseDialog = () => {
    setBillingDate('');
    setSerialNumber('');
    setOpenDialog(false);
  };

  // עמודות הטבלה
  const columns = [
    { field: "Name", headerName: "שם התשלום", width: 250 },
    { field: "payeeName", headerName: "שם המשלם", width: 150 },
    { field: "payeeId", headerName: "תעודת זהות", width: 150 },
    { field: "paymentDate", headerName: "תאריך", width: 200 },
    { field: "bankId", headerName: "בנק", width: 150 },
    { field: "branchId", headerName: "מספר סניף", width: 150 },
    { field: "accountId", headerName: "מספר חשבון", width: 150 },
    { field: "amount", headerName: "סכום לתשלום", width: 150 },
  ];

  return (
    <Box
  sx={{
    display: "flex",
    flexDirection: "column",
    height: "80vh", // גובה כולל לתיבה
    maxWidth: "100%",
  }}
>
  {/* תיבה לטבלת הנתונים */}
  <Box sx={{ flexGrow: 1, overflow: "auto" }}>
    <DataGrid
      rows={payments}
      columns={columns}
      getRowId={(row) => row.payeeNumber}
      components={{ Toolbar: GridToolbar }}
      loading={!payments.length}
      onSelectionModelChange={(newSelection) => setSelectedRows(newSelection)}
      checkboxSelection
    />
  </Box>
  {/* כפתור לפתיחת הדיאלוג */}
  <Box sx={{ mt: 2, textAlign: "center" }}>
    <Button variant="contained" color="primary" onClick={handleClickOpen}>
      הורד קובץ למס"ב
    </Button>
  </Box>
      {/* הדיאלוג להכנסת מספר סיריאלי ותאריך חיוב */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>הכנס פרטים להורדה</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="מספר סיריאלי"
            type="text"
            fullWidth
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="תאריך חיוב לקוח"
            type="date"
            fullWidth
            value={billingDate}
            onChange={(e) => setBillingDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>ביטול</Button>
          <Button 
            onClick={() => { handleShowPayments(); handleCloseDialog(); }} 
            color="primary" 
            variant="contained" 
            disabled={serialNumber === '' || billingDate === ''}
          >
            הורד
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HokBankPage;
