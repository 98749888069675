import { Box, Collapse, Container, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import appConfig from "../utils/appConfig";
import { Fragment, useEffect, useState,useContext } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { appFetch } from "../utils/fetch";
import { UserContext } from "../hooks/useUser";

function ConfirmedOperationsPage() {
  
  const url = appConfig.apiUrls.operatorsData;
  //const [data, setData ] = useState([]);
  const [zohoData, setZohoData ] = useState([]);
  const [load, setLoad ] = useState(true);

  // useEffect( () => {
  //   fetch(url)
  //   .then((res) => res.json()
  //   .then( d => setData(d)))
  //   .catch(err => alert("שגיאה בטעינת הנתונים"));
  // },[url])

  const { mosad, user } = useContext(UserContext);

  const mosadId= mosad.id;

  useEffect(() => {
    (async () => {
      try {
        const res = await appFetch(`api/zohoMosads/dataByMosadId/${mosadId}`, {
          method: 'GET',
        });
        if (!res.ok) {
          throw new Error('Failed to fetch data for mosad');
        }
        let mosadData = await res.json(); // שומר את התוצאה מהקריאה הראשונה
        mosadData= mosadData.data[0]
        console.log(mosadData);
        const accountId= mosadData.field13.id
  
        try {
          const res2 = await appFetch(`api/zohoOrganizations/OperatorsByAccountId/${accountId}`, {
            method: 'GET',
          });
          if (!res2.ok) {
            throw new Error('Failed to fetch operators');
          }
          const operatorData = await res2.json(); // תוצאה מהקריאה השנייה
          const filteredData = operatorData.filter(item => item.field1 === 'פעיל');
          setZohoData(filteredData); // שמירת התוצאה בסטייט
        } catch (error) {
          console.error('Error fetching operators:', error);
        }
      } catch (error) {
        console.error('Error fetching mosad data:', error);
      } finally {
        setLoad(false); // הגדרת הטעינה לאחר שכל הקריאות הסתיימו
      }
    })();
  }, [mosadId]); // הוספת תלות ב-mosadId
  
  
  //console.log(data)
  console.log(zohoData)

  const data_01 = zohoData.map((i, k) => ({...i, c: k+1}));

  return (
    <Container>
  
      {load ? (
        <TableRowsLoader rowsNum={10} cellNum={20} />
      ) : zohoData.length === 0 ? (
        <Typography mt={10} mb={2} variant="h3" color="appPink.main">
          לא נמצאו מפעילים
        </Typography>
      ) : (
        <>
          <Typography mt={10} mb={2} variant="h3" color="appPink.main">
            פרטי המפעילים
          </Typography>
  
          <Box sx={{ height: "60vh", overflow: "auto", maxWidth: "100%" }}>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>שם</TableCell>
                    <TableCell>שם עסק</TableCell>
                    <TableCell>טלפון</TableCell>
                    <TableCell>מייל</TableCell>
                    <TableCell>סוג</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                { !zohoData.length ? <TableRowsLoader rowsNum={10} cellNum={6} /> : data_01.map((row) => (
                    <Row key={row.ID} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
  
    </Container>
  );  
}
export default ConfirmedOperationsPage;

function Row( props ) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* <TableCell>{row.c}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.Esek_name}</TableCell>
        <TableCell>{row.mobile}</TableCell>
        <TableCell>{row.phone}</TableCell>
        <TableCell>{row.type}</TableCell> */}
        <TableCell>{row.c}</TableCell>
        <TableCell>{row.Name}</TableCell>
        <TableCell>{row.business_name}</TableCell>
        <TableCell>{row.field6}</TableCell>
        <TableCell>{row.Email}</TableCell>
        <TableCell>{row.field12}</TableCell>
        <TableCell>
          {/* {( row.area || row.details ) && <IconButton */}
           {( row.field38 ) && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> }
        </TableCell>
        
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                פרטים:
              </Typography>
              <Typography variant="p" component="div">
                {/* {row.details} */}
                {row.field38}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}


const TableRowsLoader = ({ rowsNum, cellNum }) => {
  return [...Array(rowsNum)].map((row, index) => (
    <TableRow key={index}>
      {[...Array(cellNum)].map((row, j) => <TableCell key={j}><Skeleton animation="wave" variant="text" /></TableCell> )}
    </TableRow>
  ));
};