const appConfig = {
  apiUrls: {
    //getMosadData: "https://hook.eu1.make.com/t45cnrwginsew8h99oj1baeoovvh4e3x?user=",//נתוני גננת לפי מייל1
    //getPaymentsData:"https://hook.eu1.make.com/gf8hrjfjuj9nwuglruaub4jyuv8d2a53?Id_zoho_crm=",
    //studentData: "https://hook.eu1.make.com/6v5bd1uhu9ntovmlwbclr4kcibjknq7j?Id_zoho_crm=",
    studentReminder:"https://hook.eu1.make.com/fn49s9vqdkpfsz4x4odbhfnfhv2k1zlq?id=",//שולח נתונים וחגי מוציא סמס להורה
    //studentCancelRegister:"https://hook.eu1.make.com/6ml88wkwcogw9n8drkdlns2xz7ztib5e",// שליחת ID שיבוץ וחגי מעדכן כ'דווח כשיבוץ לא נכון'2
    //operatorsData:"https://hook.integromat.com/db0mtu2vy44wy0wpi8rd7z5psp99xhzb?_=1674390912243",
    budgetsData:"https://hook.eu1.make.com/92nzwe136e5fwrni8uidqslw2v6qqpn5?SemelMosad=",
    confirmActivityPay: "https://hook.eu1.make.com/28wugdnvwt5r292se11i5avnsfm5iret",// על פי ID של אובייקט הפעלה צריך לשנות את הסטטוס ללי מה שהגננת לחצה אושר/סורב על ידי הגננת
    //getOrganization: "https://pay.billagan.co.il/wp-json/wp/v2/organization?id=",// הבאת נתוני רשות לפי ID
    notInTheListStudentData: "https://hook.eu1.make.com/o8dobkug7t1pmxddwi3guw1s4r52r3vp",// גננת שמזינה תלמיד שלא נמצא
  },
  is_mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),

  year: "תשפה"
};

export default appConfig;


export const getGreeting = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  if (currentHour >= 6 && currentHour < 12) {
    return "בוקר טוב"; // Good morning
  } else if (currentHour >= 12 && currentHour < 18) {
    return "צהריים טובים"; // Good afternoon
  } else if (currentHour >= 18 && currentHour < 24) {
    return "ערב טוב"; // Good evening
  } else {
    return "לילה טוב"; // Good night
  }
}

export const numberWithCommas = (x) => {
  return (Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}