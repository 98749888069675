import React, { useState, useContext, useEffect } from 'react';
import { Typography, Card, Box } from "@mui/material";
import { Container } from "@mui/system";
import { UserContext } from "../../hooks/useUser";
import LoadingScreen from "../../components/LoadingScreen";
import { appFetch } from "../../utils/fetch";

const CoordinatorDashboard = () => {
  const [loading, setLoading] = useState(false);
  const { coordinatorMosads,coordinatorMosadsWithData, setCoordinatorMosadsWithData, user } = useContext(UserContext);
  const [noData, setNoData] = useState('אנא המתן בזמן שהמערכת מאתרת נתונים');

  useEffect(() => {
    const fetchMosadData = async () => {
      setLoading(true);
      try {


        // נבצע בקשה עבור כל מוסד
        const mosadsData = await Promise.all(
          coordinatorMosads.map(async (mosad) => {
            console.log(mosad)
            const res = await appFetch(`api/zohoMosads/SchedulingByMosadID/${mosad.id}`, {
              method: 'GET',
            });

            if (!res.ok) {
              throw new Error(`Failed to fetch data for ${mosad.Name}`);
            }

            const data = await res.json();
            

            // מחזירים את המוסד המקורי עם הנתונים שהתקבלו
            return { ...mosad, data };
          })
        );
        if (coordinatorMosads && coordinatorMosads.length === 0) {
          setNoData(`לא נמצאו מוסדות עבור ${user.firstName} ${user.lastName}`);
          return;
        }
      // סינון המוסדות לפי סטטוס
      const filteredData = mosadsData.map((mosad) => {
        const filteredDetails = mosad?.data?.data?.filter(item => item.status !== "לא פעיל");
        return { ...mosad, data: filteredDetails };  // מחזירים את המוסד עם נתונים מסוננים
      });

      // שמירת התוצאות המסוננות
      setCoordinatorMosadsWithData(filteredData);
      

    
      } catch (error) {
        console.error('Error fetching students:', error);
      } finally {
        setLoading(false); // סיום טעינה
      }
    };

    fetchMosadData();
  }, [coordinatorMosads]); // הפעלת ה-Effect כל פעם ש-filterdMosads משתנה

  return (
    <Container sx={{ height: '100vh' }}>
      <Typography mb={2} variant="h3" color="appPink.main">
        רשימות מוסדות
      </Typography>

      <div>
        {loading ? (
          <LoadingScreen />
        ) : (
          coordinatorMosadsWithData.length === 0 ? (
            <Typography mt={10} mb={2} variant="h3" align="center" color="appPink.main">
              {noData}
            </Typography>
          ) : (
            <Box
              display="flex"
              sx={{ flexWrap: "wrap", fontSize: { xs: "8px" } }}
              justifyContent="center"
              gap={1.5}
            >
              {coordinatorMosadsWithData.map((mosad) => (
                <InsightCard
                  key={mosad.id} // הנחה שיש שדה id לכל מוסד
                  color={"appPink.main"}
                  label={`ילדים בגן ${mosad.Name}`} // הצגת שם המוסד
                  value={mosad?.data?.length || 0} // הצגת מספר התלמידים מתוך הנתונים שהתקבלו
                />
              ))}
            </Box>
          )
        )}
      </div>
    </Container>
  );
};

export default CoordinatorDashboard;

export function InsightCard({ value, color, label }) {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: { xs: "10rem", md: "12rem" }, // הגדלנו את הגובה
        textAlign: "center",
        width: { xs: "40%", md: "25%" }, // הגדלנו את הרוחב
        padding: { xs: "15px" },
      }}
    >
      <Typography
        variant="h1"
        sx={{ fontSize: { xs: "1.2rem", md: "2rem" } }} // הקטנו את הפונט
        color={color}
      >
        {value}
      </Typography>
      <Typography
        margin="5px"
        sx={{ fontSize: { xs: "0.9rem", md: "1.2rem" } }} // הקטנו את הפונט
        variant="h5" // שינינו ל-h5 להקטנת הכותרת
      >
        {label}
      </Typography>
    </Card>
  );
}
